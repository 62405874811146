<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="commonly-set">
        <div class="set-heard space-between">
          <div class="font-lighter">常用语</div>
          <img
            src="../../../assets/forget/15.png"
            alt=""
            class="pointer"
            @click.stop="hide"
          />
        </div>
        <div class="commonly-item">
          <div class="space-between" v-for="item in customizeList" :key="item.id">
            <div class="item-left row-center font-lighter text-main">
              <div></div>
              <div>{{ item.content }}</div>
            </div>
            <div class="item-right row-center">
              <div class="position-relative">
                <div
                  class="position-absolute mouser-img top-0 left-0"
                  :class="downShow ? 'downClass' : ''"
                  v-if="downIndex == item.id"
                >
                  <img src="../../../assets/glxt/40.png" alt="" />
                  <div class="position-absolute top-0 left-0 font-normal text-white">
                    {{ downShow ? "下移" : "上移" }}
                  </div>
                </div>
                <div class="row-center tighr-imh">
                  <img
                    src="../../../assets/glxt/38.png"
                    alt=""
                    class="pointer"
                    @mouseenter="mouseenterDown(item.id, false)"
                    @mouseleave="mouseleaveDown"
                    @click="downClick(item, 1)"
                  />
                  <img
                    src="../../../assets/glxt/39.png"
                    alt=""
                    class="pointer"
                    @mouseenter="mouseenterDown(item.id, true)"
                    @mouseleave="mouseleaveDown"
                    @click="downClick(item, 2)"
                  />
                </div>
              </div>
              <div class="row-center">
                <div class="riht-text pointer" @click="editText(item)">编辑</div>
                <div class="pointer" @click="deleteCommonly(item.id)">删除</div>
              </div>
            </div>
          </div>
          <div v-if="customizeList.length == 0" class="not-commonly-list flex-center">
            暂无常用语，请添加!
          </div>
        </div>
        <div class="text-blue font-lighter pointer add-commolay" @click="inputClick">
          添加常用语
        </div>
        <div class="add-commolay-inptu" v-show="inputShow">
          <div>
            请不要填写手机、QQ、微信等联系方式或广告信息，否则系统将封禁您的账号！
          </div>
          <div class="text-input">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="textarea"
              maxlength="200"
              show-word-limit
              resize="none"
            >
            </el-input>
          </div>
          <div class="row-center text-botton">
            <div class="text-white flex-center bg-blue pointer" @click="submitClickOne">
              确定
            </div>
            <div class="text-blue flex-center pointer" @click="inputShow = false">
              取消
            </div>
          </div>
        </div>
      </div>
    </div>
    <mask-model
      ref="mask"
      :btnCancel="true"
      title="确认删除该条常用于吗？"
      width="480px"
      @submitClick="submitClick"
    >
      <div style="margin: 30px"></div>
    </mask-model>
  </div>
</template>

<script>
import maskModel from "../../../components/mask.vue";
import api from "../../../api/recruiter";
export default {
  components: { maskModel },

  data() {
    return {
      status: false,
      customizeList: [],
      textarea: "",
      inputShow: false,
      downShow: false,
      downIndex: "",
      query: {
        id: "",
        content: "",
        is_default: "",
        weight: "",
        type: 2,
      },
      sayId: "",
    };
  },

  created() {},

  mounted() {},

  methods: {
    // 获取常用语
    getSay() {
      api.getSay({ type: 2 ,pageSize:100}).then((res) => {
        if (res.code == 200) {
          this.customizeList = res.data.data;
        }
      });
    },
    show() {
      this.getSay();
      this.status = true;
    },
    hide() {
      this.status = false;
    },
    inputClick() {
      this.inputShow = !this.inputShow;
    },
    // 删除
    deleteCommonly(id) {
      this.sayId = id;
      this.$refs.mask.show();
    },
    // 确定删除
    submitClick() {
      api.deleteSay({ id: this.sayId }).then((res) => {
        if (res.code == 200) {
          this.getSay();
          this.$util.msg("删除成功");
        }
      });
    },
    // 上移下移
    mouseenterDown(index, flag) {
      this.downShow = flag;
      this.downIndex = index;
    },
    mouseleaveDown() {
      this.downIndex = "";
    },
    downClick(item, index) {
      let query = item;
      if (index == 1) {
        query.weight += 1;
      } else {
        if (query.weight == 0) {
          this.$util.msg("级别以及最低了，不可在下移了", "warning");
          return;
        }
        query.weight -= 1;
      }
      api.setSay(query).then((res) => {
        if (res.code == 200) {
          this.getSay();
          this.$util.msg(res.msg);
        }
      });
    },
    // 编辑
    editText(item) {
      this.textarea = item.content;
      this.query = item;
      this.inputShow = true;
    },
    submitClickOne() {
      // console.log(this.query)
      let query = this.query;
      if (!this.textarea) {
        this.$util.msg("请输入内容", "error");
        return;
      }
      query.content = this.textarea;
      api.setSay(query).then((res) => {
        if (res.code == 200) {
          this.getSay();
          this.textarea = "";
          this.$util.msg(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.commonly-set {
  width: 1047px;
  height: 805px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 31px 38px 30px 35px;
  .set-heard {
    padding-left: 2px;
    color: #3d4966;
    img {
      width: 11px;
      height: 11px;
    }
  }
  .commonly-item {
    width: 100%;
    height: 449px;
    overflow-y: auto;
    padding-right: 1px;
    padding-top: 60px;
    > div {
      margin-bottom: 29px;
    }
    .item-left {
      div:first-child {
        width: 8px;
        height: 8px;
        background: #dedfe5;
        border-radius: 50%;
        margin-right: 15px;
      }
    }
    .item-right {
      img {
        width: 13px;
        height: 17px;
      }
      .mouser-img {
        top: -60px;
        left: -34px;
        img {
          width: 81px;
          height: 56px;
        }
        div {
          width: 100%;
          height: 100%;
          text-align: center;
          line-height: 40px;
        }
      }
      .downClass {
        left: 3px;
      }
      .tighr-imh {
        margin-right: 39px;
        img:first-child {
          margin-right: 23px;
        }
      }
      color: rgba(108, 146, 140, 0.5);
      font-size: 14px;
      .riht-text {
        margin-right: 9px;
      }
    }
    .not-commonly-list {
      width: 100%;
      height: 100%;
      color: #9fa3b0;
      font-size: 20px;
    }
  }
  .commonly-item::-webkit-scrollbar {
    display: none;
  }
  .add-commolay {
    padding-left: 25px;
  }
  .add-commolay-inptu {
    margin-top: 20px;
    padding-right: 5px;
    padding-left: 25px;
    font-size: 14px;
    > div:first-child {
      color: #fc6f3c;
      margin-bottom: 21px;
    }
    .text-input {
      height: 153px;
      width: 100%;
      margin-bottom: 21px;
      /deep/.el-textarea {
        height: 100%;
      }
      /deep/.el-textarea__inner {
        height: 100%;
      }
    }
    .text-botton {
      div {
        width: 92px;
        height: 34px;
      }
      div:last-child {
        border: 1px solid #126bf9;
        margin-left: 24px;
      }
    }
  }
}
</style>
