<template>
	<div @click="hideOffen" class="el-interactive">
		<div class="interactive-select">
			<el-select v-model="valSim" @change="changeSim" placeholder="请选择">
				<el-option v-for="item in optionsOne" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
		</div>
		<div class="interactive-heard-tab row-center text-main">
			<div v-for="(item, index) in heardTabList" :key="index" class="pointer" :class="[heardTabIndex === index ? 'heardTabClass' : '', heardTabIndex === 0 && heardTabIndex === index ? 'heardCurrent' : '',]" @click="headTabClick(index)">
				{{ item }}
			</div>
		</div>
		<div class="el-chat-interactive space-between">
			<div class="el-chat-left column">
				<div class="chat-left-heard">
					<div class="space-between chat-left-heard-center" v-if="chartList.length > 0">
						<div class="row-center left-input">
							<img src="../../../assets/glxt/35.png" alt="" />
							<input type="text" @focus="focusClick" v-model="nameInput" />
						</div>
						<div class="right-box row-center font-normal pointer" @click="checkUnreadClick"
							v-if="focusShow">
							<div v-if="checkUnread == false"></div>
							<img src="../../../assets/glxt/27.png" v-else alt="" />
							<div>未读</div>
						</div>
						<img src="../../../assets/glxt/36.png" v-else class="cha-img pointer" @click="differenceClick"
							alt="" />
					</div>
					<div class="font-normal chat-left-heard-none-text" v-else>近期联系人</div>
				</div>
				<div class="chart-list one">
					<div class="chart-list-none font-normal text-main flex-center" v-if="chartList.length == 0">
						暂无联系人
					</div>
					<div class="list-item space-between pointer" v-for="item in chartList"
						:key="item.userProfile.userID"
						:class="pointerIndex == item.userProfile.userID ? 'pointerClass' : ''"
						@mouseenter="mouseenterClick(item.userProfile.userID)" @click="userClick(item.userProfile.userID, item.lastMessage.payload.extension)">
						<img :src="item.userProfile.avatar ? item.userProfile.avatar : avatar" alt="" />
						<div class="item-right one direction">
							<div class="space-between font-normal text-main">
								<span>{{ item.userProfile.nick ? item.userProfile.nick : "未知" }}</span>
								<!-- 计算时间 -->
								<span v-if="focusShow">
									{{item.lastMessage? $util.dateFormat(item.lastMessage.lastTime * 1000): ""}}
								</span>
							</div>
							<div v-if="focusShow" class="text-ellipsis ellip-text" style="word-break: break-all">
								<span v-if="item.unreadCount">【{{ item.unreadCount == 0 ? "已读" : "未读" }}】</span>
								<span v-if="
                    item.lastMessage.payload.data !== 'cvText' &&
                    item.lastMessage.payload.data !== 'inte'
                  ">{{
                    item.lastMessage ? item.lastMessage.payload.description : ""
                  }}</span>
								<span v-if="item.lastMessage.payload.data == 'cvText'">接收到附件简历</span>
								<span v-if="item.lastMessage.payload.data == 'inte'">面试邀请已发送，等待对方接受</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div @click.stop="hideOffen" class="el-chat-right column one">
				<div class="el-chart-right-heard position-relative">
					<!-- 判断是否发起了聊天 -->
					<div v-if="chartId" class="el-chart-right-child">
						<img src="../../../assets/glxt/33.png" class="el-img" alt="" v-if="rightTabIndex == 0" />
						<img src="../../../assets/glxt/34.png" class="el-img" alt="" v-else />
						<div class="position-absolute top-0 el-right-button-tab row">
							<div class="taxt-main font-normal pointer" :class="rightTabIndex == 0 ? 'active-tab' : ''"
								@click="rightTabClick(0)">
								聊天
							</div>
							<div class="taxt-main font-normal pointer" :class="rightTabIndex == 1 ? 'active-tab' : ''"
								@click="rightTabClick(1)">
								在线简历
							</div>
						</div>
					</div>
				</div>
				<!-- 右侧聊天界面 -->
				<div class="column one" v-if="rightTabIndex == 0 && chartId">
					<div class="chart-right-center one position-relative">
						<!-- 聊天框悬浮的用户工作内容 -->
						<div class="center-user-detail position-absolute top-0 left-0 space-between font-normal"
							v-if="resumeObj">
							<div class="column-between user-detail-left">
								<div class="row-center">
									<!-- <div class="user-name">{{ resumeObj.name ? resumeObj.name : resumeObj.user_name }}</div> -->
									<div class="user-name" v-if="resumeObj.privates.protect_name == 0">
										{{ resumeObj.name ? resumeObj.name : resumeObj.user_name }}
									</div>
									<div class="user-name" v-else>
										{{ resumeObj.name.slice(0, 1)
                    }}{{ resumeObj.gender.value == 1 ? "先生" : "女士" }}
									</div>
									<div class="row-center user-job-detail">
										<span>{{ resumeObj.detail.province }}</span>
										<div>|</div>
										<span>{{ resumeObj.other_info.age }}岁</span>
										<div>|</div>
										<span>工作{{ resumeObj.other_info.work_year }}年</span>
										<div>|</div>
										<span>{{ resumeObj.other_info.job_status }}</span>
									</div>
								</div>
								<div class="row-center" v-if="resumeObj.work.length > 0">
									<div>曾任：{{ resumeObj.work[0].company_name }}</div>
									<div class="user-job-one">{{ resumeObj.work[0].work_name }}</div>
									<div class="user-job" v-if="resumeObj.job_intention.length > 0">
										期望职位：{{ resumeObj.job_intention[0].position_name }}
									</div>
									<div v-if="resumeObj.job_intention.length > 0">
										{{ resumeObj.job_intention[0].salary_min }}-{{
                      resumeObj.job_intention[0].salary_max
                    }}
									</div>
								</div>
							</div>
							<div class="text-blue" v-if="extension.jop">
								沟通职位：{{ extension.jop }}
							</div>
						</div>
						<div class="chart-rightr-item font-normal" @scroll="scrollEventSay">
							<div v-for="(item, index) in wordList" :key="item.id">
								<!-- 时间 -->
								<div class="word-time flex-center font-normal" v-show="index == 0">
									{{ $util.dateFormat(item.time * 1000) }}
								</div>
								<!-- index不为第一个切两条消息相差5分钟才显示这个时间 -->
								<div class="word-time flex-center font-normal" v-if="index !== 0">
									{{item.time - wordList[index - 1].time > 5 * 60 ? $util.dateFormat(item.time * 1000): ""}}
								</div>
								<!-- 对方的 -->
								<div class="word row-center" v-if="item.mineMsg == false">
									<img :src="item.avatar ? item.avatar : avatar" alt="" />
									<!-- 因为文本中可能包含表情包，所以得借助方法解析对方的文本 -->
									<div class="flex-center" style="word-break: break-all"
										v-html="emoji.decoder.decode(item.contactText)"></div>
								</div>
								<!-- 对方发送的电话邀请交换-->
								<div class="el-word-phone row-center" v-if="item.phoneShow">
									<img :src="item.avatar ? item.avatar : avatar" alt="" />
									<div class="word-phone-div font-normal">
										<div>我想和你交换联系方式 您是否同意</div>
										<div class="word-phone-button row">
											<div @click="sendPhoneOne(item.id)" class="pointer">拒绝</div>
											<div class="text-blue pointer" @click="sendPhone(item.id)">同意</div>
										</div>
									</div>
								</div>
								<!-- 对方发送的电话邀请交换-->
								<div class="el-word-phone row-center" v-if="item.cvText">
									<img :src="item.avatar ? item.avatar : avatar" alt="" />
									<div class="word-phone-div font-normal">
										<div class="text-ellipsis">
											{{ JSON.parse(item.contactText).annex_name }}
										</div>
										<div class="word-phone-button row">
											<div class="text-blue pointer checkCv-class"
												@click="checkCv(item.contactText)">
												立即下载
											</div>
										</div>
									</div>
								</div>
								<!-- 交换联系方式,交换简历 -->
								<div class="exchange flex-center font-normal text-main"
									v-if="item.connect && item.phoneShow == false">
									{{ item.contactText }}
								</div>
								<div class="exchange flex-center font-normal text-main" v-if="item.cv">
									{{ item.contactText }}
								</div>
								<!-- 我的 -->
								<div class="word-my row" v-if="item.mineMsg">
									<div class="el-have-read flex-center text-white"
										:class="item.read == false ? 'read-false' : ''">
										{{ item.read ? "已读" : "未读" }}
									</div>
									<div class="row-center word-my-center">
										<div class="text-white bg-blue flex-center" style="word-break: break-all"
											v-html="emoji.decoder.decode(item.contactText)"></div>
										<img :src="item.avatar ? item.avatar : avatar" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="position-relative commonly-userd">
						<!-- 常用语弹窗 -->
						<div class="el-commonly position-absolute top-0 left-0" v-if="commonlyShow">
							<img src="../../../assets/glxt/37.png" class="el-img" alt="" />
							<div class="position-absolute commonly-center top-0 left-0">
								<div class="commonly-item font-normal">
									<div class="item space-between">
										<div class="font-lighter item-heard">常用语</div>
										<div class="text-blue" @click.stop="setCommonly">设置</div>
									</div>
									<div class="item space-between" :class="commlyIndex == index ? 'item-index' : ''"
										v-for="(item, index) in customizeList" :key="index"
										@mouseenter="mouseenterCommonly(index)" @mouseleave="mouseleaveCommonly(index)"
										@click="commonlySend(item.content)">
										<div class="text-main">{{ item.content }}</div>
										<div class="text-blue" v-show="commlyIndex == index">发送</div>
									</div>
									<div v-if="customizeList.length == 0" class="not-commonly pointer"
										@click.stop="setCommonly">
										暂无常用语,请添加
									</div>
								</div>
							</div>
						</div>
						<!-- 索要简历弹窗 -->
						<div class="ask-for position-absolute top-0 left-0" v-if="askShow">
							<img src="../../../assets/glxt/41.png" alt="" />
							<div class="position-absolute top-0 left-0 ask-center direction">
								<div>确定向人才索要简历吗？</div>
								<div class="row-center">
									<div class="ask-botton flex-center pointer" @click="askShow = false">
										取消
									</div>
									<div class="bg-blue text-white flex-center pointer" @click="askSend">
										确定
									</div>
								</div>
							</div>
						</div>
						<!-- 请求交换电话弹窗 -->
						<div class="ask-for phone-for position-absolute top-0 left-0" v-if="phoneShow">
							<img src="../../../assets/glxt/41.png" alt="" />
							<div class="position-absolute top-0 left-0 ask-center direction">
								<div>请求与对方交换电话</div>
								<div class="row-center">
									<div class="ask-botton flex-center pointer" @click="phoneShow = false">
										取消
									</div>
									<div class="bg-blue text-white flex-center pointer" @click="phoneSend">
										确定
									</div>
								</div>
							</div>
						</div>
						<!-- 不合适弹窗 -->
						<div class="inappropriate position-absolute top-0 right-0" v-if="inappropriateShow">
							<img src="../../../assets/glxt/42.png" alt="" />
							<div class="inappropriate-center position-absolute top-0 left-0 direction">
								<div class="space-between font-normal">
									<span class="font-lighter pointer" @click="inappropriateSendClick">发送消息</span>
									<span class="text-blue pointer" @click="updateInapp">{{
                    inappList.length > 0 ? "修改" : "添加"
                  }}</span>
								</div>
								<div class="flex-center text-ellipsis" v-if="inappList.length > 0">
									{{ inappList[0].content }}
								</div>
								<div class="flex-center text-ellipsis" v-else>暂无不合适用语，请添加</div>
							</div>
						</div>
						<!-- 聊天输入框 -->
						<div class="chart-right-bottom column" v-if="resumeObj">
							<div class="space-between right-bottom-img font-normal">
								<div class="row-center right-bottom-img-left">
									<div class="pointer" @click="commonlyClick">
										<img src="../../../assets/glxt/28.png" alt="" />
									</div>
									<div class="row-center div-one-img pointer" @click="askClick">
										<img src="../../../assets/glxt/29.png" alt="" />
										<div :class="annexObjShow == false ? 'resumeObj' : ''">简历</div>
									</div>
									<div class="row-center div-two-img pointer" @click="phoneClick" v-if="resumeObj">
										<img src="../../../assets/glxt/30.png" alt="" />
										<div :class="resumeObj.privates.protect_phone == 1 ? 'div-two-text' : ''">
											电话
										</div>
									</div>
								</div>
								<div class="row-center right-bottom-img-left">
									<div class="div-three-img row-center pointer" @click="interviewClick">
										<img src="../../../assets/glxt/31.png" alt="" />
										<div>约面试</div>
									</div>
									<div class="div-four-img row-center pointer" @click="inappropriateClick">
										<img src="../../../assets/glxt/32.png" alt="" />
										<div>{{ link_status != 2 ? "不合适" : "取消不合适" }}</div>
									</div>
								</div>
							</div>
							<div class="chart-input one">
								<el-input type="textarea" placeholder="请输入内容" v-model="textarea"
									@keyup.enter.native="handleSearchClick">
								</el-input>
							</div>
							<div class="send-button row-end font-normal">
								<div>按Enter键发送</div>
								<div class="flex-center pointer" :class="areaShow ? 'area-active' : ''"
									@click="sendClick">
									发送
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 右侧在线简历页面 -->
				<div class="column one right-conent-cv" v-if="rightTabIndex == 1 && chartId && resumeObj">
					<div class="one cv-detail row">
						<img :src="resumeObj.avatar_url ? resumeObj.avatar_url : avatar" alt="" />
						<div class="el-detail-text one">
							<div class="el-detail-text-one space-between">
								<div class="detail-text-one-left column-between">
									<div class="text-main" v-if="resumeObj.privates.protect_name == 0">
										{{ resumeObj.name ? resumeObj.name : resumeObj.user_name }}
									</div>
									<div class="text-main" v-else>
										{{ resumeObj.name.slice(0, 1)
                    }}{{ resumeObj.gender.value == 1 ? "先生" : "女士" }}
									</div>
									<div class="font-normal">{{ resumeObj.detail.advantage }}</div>
								</div>
								<div class="detail-text-one-right row-center font-normal text-main">
									<span>{{ resumeObj.detail.province }}</span>
									<div>|</div>
									<span>{{ resumeObj.other_info.age }}岁</span>
									<div>|</div>
									<span>工作{{ resumeObj.other_info.work_year }}年</span>
									<div>|</div>
									<span>{{ resumeObj.other_info.job_status }}</span>
								</div>
							</div>
							<div class="el-detail-text-two font-normal text-main row-center"
								v-if="resumeObj.job_intention.length > 0">
								<div>期望职位：{{ resumeObj.job_intention[0].position_name }}</div>
								<div>
									{{ resumeObj.job_intention[0].salary_min }}-{{
                    resumeObj.job_intention[0].salary_max
                  }}
								</div>
							</div>
							<div v-for="item in resumeObj.work" :key="item.id">
								<div class="el-detail-text-three font-normal text-main space-between">
									<div class="text-three-left row-center">
										<div>
											工作经历：<span class="text-blue">{{ item.company_name }}</span>
										</div>
										<div>| {{ item.work_name }}</div>
									</div>
									<div style="opacity: 0.8">{{ item.job_times }}</div>
								</div>
								<div class="text-main font-normal row el-detail-text-four">
									<div>内容：</div>
									<div>
										<div v-for="(workContent, index) in item.splitAdd" :key="index"
											class="el-detail-text-four-item">
											{{ workContent }}
										</div>
									</div>
								</div>
							</div>

							<div class="text-main font-normal space-between el-detail-text-five"
								v-if="resumeObj.school.length > 0">
								<div class="row-center">
									<div>教育经历：</div>
									<div class="row-center el-detail-text-five-text">
										<span>{{ resumeObj.school[0].school_name }}</span>
										<div>|</div>
										<span>{{ resumeObj.school[0].major }}</span>
										<div>|</div>
										<span>{{ resumeObj.school[0].education }}</span>
									</div>
								</div>
								<div style="opacity: 0.8">{{ resumeObj.school[0].school_times }}</div>
							</div>
							<div class="text-main font-normal row el-detail-text-four">
								<div>在校经历：</div>
								<div>
									<div v-for="(workContent, index) in experience_one" :key="index"
										class="el-detail-text-four-item">
										{{ workContent }}
									</div>
								</div>
							</div>
							<div class="text-main font-normal row el-detail-text-four" v-if="resumeObj.certificate">
								<div>资格证书：</div>
								<div>
									<span v-for="(workContent, index) in resumeObj.certificate.certificates"
										:key="index" class="el-detail-text-four-item-span">
										{{ workContent }}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="cv-detail-button row-end font-normal">
						<!-- <div class="row-center pointer" @click="inappropriateClick">
              <img src="../../../assets/glxt/32.png" alt="" />
              <span>不合适</span>
            </div> -->
						<div class="el-button-hf flex-center pointer" @click="replyClick">回复</div>
					</div>
				</div>
				<!-- 右侧为空的页面 -->
				<div class="column one chart-id-class" v-if="chartId == ''">
					<img src="../../../assets/glxt/43.png" alt="" />
					<div>Tips：<span>您与人才在线沟通的记录都在这里</span></div>
				</div>
			</div>
		</div>
		<commonly-set ref="setMask" :customizeList="customizeList"></commonly-set>
		<!-- 发送面试邀请 -->
		<mask-model ref="maskModel" width="482px" sureContent="发送" title="发送面试邀请" @submitClick="interviewSendClick"
			:interview="false">
			<div class="update-eidt">
				<div class="row-center eift-item">
					<div class="text-main font-normal">面试职位：</div>
					<div class="div-select">
						<el-select v-model="interviewObj.positionValue" @change="positionChange" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="row-center eift-item-one">
					<div class="text-main font-normal">面试时间：</div>
					<div class="row select-item">
						<div class="select-item-div select-item-div-one">
							<el-date-picker v-model="timeValue" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
								:picker-options="pickerOptions">
							</el-date-picker>
						</div>
						<div class="select-item-div">
							<el-time-select v-model="timeValueOne" :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }" placeholder="任意时间点" value-format="HH:mm">
							</el-time-select>
						</div>
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">面试地址：</div>
					<div class="div-select">
						<el-input placeholder="请输入详细地址" v-model="interviewObj.address" clearable>
						</el-input>
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">联系人姓名：</div>
					<div class="div-select div-select-one">
						<el-input placeholder="请输入联系人姓名" v-model="interviewObj.name" clearable>
						</el-input>
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">联系方式：</div>
					<div class="div-select">
						<el-input placeholder="请输入联系方式" v-model="interviewObj.phone" clearable>
						</el-input>
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">备注事项：</div>
					<div class="div-select">
						<el-input type="textarea" placeholder="请输入备注事项，最多140字" v-model="interviewObj.remark"
							maxlength="140" show-word-limit>
						</el-input>
					</div>
				</div>
			</div>
		</mask-model>
		<!-- 设置不合适快捷语 -->
		<mask-model ref="inappropriateModel" width="482px" :title="inappTitle" :btnCancel="true" :interview="false"
			@submitClick="inappSubmitClick">
			<div class="inappropriate-model">
				<el-input type="textarea" placeholder="输入快捷语" v-model="inappQuery.content" maxlength="30"
					show-word-limit resize="none">
				</el-input>
			</div>
		</mask-model>
	</div>
</template>

<script>
	// 设置常用语弹窗
	import TIM from "tim-js-sdk";
	import commonlySet from "../components/commonly.vue";
	import maskModel from "../../../components/mask.vue";
	import EmojiDecoder from "../../../util/EmojiDecoder";
	import {
		emojiName,
		emojiUrl,
		emojiMap
	} from "@/util/emojiMap.js";
	import {
		mapState
	} from "vuex";
	import api from "../../../api/user";
	import entApi from "../../../api/enterprises";
	import recruiterApi from "../../../api/recruiter";
	import toolApi from "../../../api/tool";
	export default {
		components: {
			commonlySet,
			maskModel,
		},
		computed: {
			// 2. 解构状态作为计算属性
			...mapState(["userDetail"]),
		},
		data() {
			return {
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					},
				},
				avatar: require("../../../assets/login/922028.png"),
				optionsOne: [],
				valSim: 0,
				heardTabList: ["全部", "沟通中", "不合适"],
				heardTabIndex: 0,
				input3: "",
				checkUnread: false,
				nameInput: "",
				focusShow: true,
				pointerIndex: "",
				chartList: [],

				rightTabIndex: 0,
				textarea: "",
				areaShow: false,
				wordList: [],
				// 弹窗相关的 布尔值
				commlyIndex: "",
				commonlyShow: false,
				customizeList: [], //常用语
				askShow: false,
				phoneShow: false,
				inappropriateShow: false,
				options: [],
				value: "",
				input: "",
				value2: new Date(2016, 9, 10, 8, 30),
				// 选中的哪一个用户的id
				chartId: "",
				// 自己的id
				userId: "",
				destory: true,
				// 判断与当前人聊天的数据是否已经拉取完
				isCompleted: true,
				nextReqMessageID: "", //拉取更多信息时的id参数
				concatShow: true,
				loading: null,
				scrollHeight: 0,
				//定义表情列表
				emoji: {
					url: emojiUrl,
					map: emojiMap,
					show: false,
					decoder: new EmojiDecoder(emojiUrl, emojiMap),
				},
				// 发送面试邀请的数据
				interviewObj: {
					position: "",
					positionValue: "",
					time: "",
					address: "",
					name: "",
					phone: "",
					remark: "",
				},
				// 面试时间
				timeValue: "",
				timeValueOne: "",
				// 扩展字段
				extension: null,
				resumeObj: null,
				experience_one: [], //资格证书列表
				timeShow: true, //防止用户不间断的输入
				// 备用的会话列表
				chartListOne: [],
				// 邀约面试后端所需的数据
				interObj: {
					user_id: "", //用户id
					enterprise_id: "", //企业id
					ent_name: "", //企业名称
					position_id: "", //职位id
					position_name: "", //职位名称
					province_id: "", //省份id,
					province: "", //省名称
					city_id: "", //市id
					city: "", //市名称
					address: "", //详细地址
					interview_time: "", //面试时间
					hr_name: "",
					hr_phone: "",
					remark: "",
				},
				inappList: [],
				inappTitle: "修改快捷语",
				inappQuery: {
					id: "",
					content: "",
					type: 3,
				},
				annexObjShow: false,
				link_status: 0,
				exchangeList: []
			};
		},

		created() {
			// 接收路由跳转过来的用户参数id
			if (this.$route.query.id) {
				this.chartId = this.$route.query.id;
				this.pointerIndex = this.chartId;
				this.userClick(this.chartId);
				this.getResume(this.chartId);
			}
			// 登录腾讯即时通讯
			let userSig = JSON.parse(localStorage.getItem("sig"));
			this.userId = userSig.userId;
			this.monitor();
		},
		// 组件销毁
		beforeDestroy() {
			this.destory = false;
		},
		mounted() {
			this.$nextTick(() => {
				this.readyList();
			});
			this.entPosiList();
			this.getExchangeList();
		},
		watch: {
			nameInput(val) {
				if (val.length > 0) {
					this.focusShow = false;
					if (this.timeShow) {
						this.timeShow = false;
						let chartList = this.chartListOne;
						let list = chartList.filter((item) => {
							if (item.userProfile.nick.indexOf(val) !== -1) {
								return item;
							}
						});
						this.chartList = list;
						setTimeout(() => {
							this.timeShow = true;
						}, 5000);
					} else {
						this.$util.msg("请勿频繁搜索", "warning");
					}
				} else {
					this.focusShow = true;
					this.readyList();
				}
			},
			textarea(val) {
				if (val.length > 0) {
					this.areaShow = true;
				} else {
					this.areaShow = false;
				}
			},
		},
		methods: {
			// 更新交换电话
			upExchangePhone(id, status){
				if(!id) return;
				api.upExchangePhone({'im_id':id,'status':status}).then((res) => {
					console.log('===upExchangePhone===',res)
				})
			},
			// 获取交换列表
			getExchangeList(){
				var that = this;
				api.getExchangeList({'is_hr':1}).then((res) => {
					console.log('===getExchangeList===',res)
					that.exchangeList = res.data.data;
				})
			},
			setLinkStatue(user_id, position_id) {
				entApi.setLineStatue({
					position_id,
					link_status: 1,
					user_id,
				});
			},
			getLinkStatus(id) {
				entApi.getLinkStatus({
					user_id: id
				}).then((res) => {
					if (res.code == 200) {
						this.link_status = res.data.link_status;
					}
				});
			},
			// 查看附件简历
			checkCv(url) {
				window.location.href = JSON.parse(url).annex_url;
			},

			// 筛选职位
			changeSim() {
				if (this.valSim !== 0) {
					let chartList = this.chartListOne;
					let extension = null;
					let list = chartList.filter((item) => {
						if (item.lastMessage.payload.extension) {
							extension = JSON.parse(item.lastMessage.payload.extension);
							if (extension.jopId == this.valSim) {
								return item;
							}
						}
					});
					this.chartList = list;
				} else {
					this.readyList();
				}
			},
			// 获取企业发布的职位
			entPosiList() {
				let entDetail = JSON.parse(localStorage.getItem("entDetail"));
				let id = entDetail.id;
				entApi.simpleList({
					enterprise_id: id
				}).then((res) => {
					if (res.code == 200) {
						res.data.unshift({
							id: 0,
							position_name: "全部",
						});
						this.optionsOne = res.data.map((item) => {
							return {
								value: item.id,
								label: item.position_name,
							};
						});

						let options = res.data.map((item) => {
							return {
								value: item.id,
								label: item.position_name,
							};
						});
						options.shift();
						this.options = options;
					}
				});
				this.interObj.enterprise_id = entDetail.id;
				this.interObj.ent_name = entDetail.ent_name;
				this.interObj.province_id = entDetail.province_id;
				this.interObj.province = entDetail.province;
				this.interObj.city_id = entDetail.city_id;
				this.interObj.city = entDetail.city;
			},
			// 选择职位
			positionChange(e) {
				let optionActive = this.options.filter((item) => {
					return item.value == e;
				});
				this.interviewObj.position = optionActive[0].label;
				this.interObj.position_name = optionActive[0].label;
				this.interObj.position_id = e;
			},
			// 监听im相关数据
			monitor() {
				let onMessageReceived = (event) => {
					let obj;
					let payload = event.data[0].payload;
					let message = event.data[0];
					if (message.from !== this.chartId) return;
					if (payload.data == "text") {
						obj = {
							mineMsg: false,
							contactText: payload.description,
							read: false,
							id: message.ID,
							time: message.clientTime,
							avatar: message.avatar,
						};
					} else if (payload.data == "cv") {
						obj = {
							cv: true,
							contactText: payload.description,
							id: message.ID,
							time: message.clientTime,
							avatar: message.avatar,
						};
					} else if (payload.data == "connect") {
						obj = {
							connect: true,
							contactText: payload.description,
							phoneShow: true,
							id: message.ID,
							time: message.clientTime,
							avatar: message.avatar,
						};
					} else if (payload.data == "img") {
						obj = {
							img: true,
							contactText: payload.description,
							id: message.ID,
							time: message.clientTime,
							avatar: message.avatar,
						};
					} else if (payload.data == "cvText") {
						obj = {
							cvText: true,
							contactText: payload.description,
							id: message.ID,
							time: message.clientTime,
							avatar: message.avatar,
						};
					}
					this.wordList.push(obj);
					this.$nextTick(() => {
						if (this.destory && this.chartId) {
							this.scrollEvent();
						}
					});
				};
				// 监听对方发过来的消息
				this.$tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);

				let conversationList = (event) => {
					// this.chartList = event.data;
					// this.chartListOne = event.data;
					this.headTabClick(this.heardTabIndex)
					// hyz：重新获取交换列表
					this.getExchangeList();
				};
				// 监听会话列表
				this.$tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, conversationList);
				// 监听消息的已读,如果已读回执之后会进入
				let onMessageReadByPeer = (event) => {
					this.wordList.forEach((item) => {
						if (item.mineMsg) {
							item.read = true;
						}
					});
					// console.log(this.wordList)
				};
				this.$tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer);
			},
			// 监听滚动条的高度，滚动到顶部后拉取聊天数据
			scrollEventSay(e) {
				if (!this.chartId) return;
				this.scrollHeight = e.srcElement.scrollHeight;
				if (e.srcElement.scrollTop == 0 && !this.isCompleted) {
					this.loading = this.$loading({
						lock: true,
						text: "加载中...",
						spinner: "el-icon-loading",
						background: "rgba(0, 0, 0, 0.7)",
					});
					this.concatShow = false;
					this.getSayList({
						conversationID: `C2C${this.chartId}`,
						nextReqMessageID: this.nextReqMessageID,
						count: 15,
					});
				}
			},
			// 调取会话列表
			readyList() {
				// 拉取会话列表
				let promise = this.$tim.getConversationList();
				promise.then((imResponse) => {
					const conversationList = imResponse.data.conversationList;
					// console.log("会话列表", conversationList);
					this.chartList = conversationList;
					this.chartListOne = conversationList;
				}).catch((imError) => {});
			},
			// 拉去某个人的会话数据
			getSayList(options) {
				console.log('======================================')
				// 打开某个会话时，第一次拉取消息列表，注意！第一次拉取时不要传入 nextReqMessageID
				let promise = this.$tim.getMessageList(options);
				promise.then((imResponse) => {
					let messageList = imResponse.data.messageList; // 消息列表。
					// 获取最后一个聊天内容并将扩展字段赋值给data中的extension
					if (messageList[messageList.length - 1].payload.extension) {
						this.extension = JSON.parse(
							messageList[messageList.length - 1].payload.extension
						);
						// console.log(this.extension);
					}
					// console.log("聊天列表", messageList);
					let wordList = messageList.map((item) => {
						if (item.from == this.userId && item.payload.data == "text") {
							return {
								mineMsg: true,
								contactText: item.payload.description,
								read: item.isPeerRead ? true : false,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.payload.data == "cv") {
							return {
								cv: true,
								contactText: item.payload.description,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.from == this.userId && item.payload.data == "connect") {
							return {
								connect: true,
								contactText: item.payload.description,
								phoneShow: false,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.from !== this.userId && item.payload.data == "text") {
							return {
								mineMsg: false,
								contactText: item.payload.description,
								read: false,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.from !== this.userId && item.payload.data == "connect") {
							return {
								connect: true,
								contactText: item.payload.description,
								phoneShow: true,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.payload.data == "inte") {
							return {
								mineMsg: true,
								contactText: "面试邀请已发送，等待对方接受",
								read: item.isPeerRead ? true : false,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.payload.data == "cvText") {
							return {
								cvText: true,
								contactText: item.payload.description,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						}
					});
					let wordListOne = wordList.filter((item) => {
						return item;
					});
					if (this.concatShow) {
						this.wordList = wordListOne;
						this.scrollEvent();
					} else {
						// this.wordList=this.wordList.concat(wordListOne)
						for (let i = wordListOne.length - 1; i >= 0; i--) {
							this.wordList.unshift(wordListOne[i]);
						}
						this.$nextTick(() => {
							let msg = document.getElementsByClassName("chart-rightr-item")[0]; // 获取对象
							msg.scrollTop = msg.scrollHeight - this.scrollHeight; // 滚动高度
							this.loading.close();
						});
					}
					console.log('===========================',this.wordList)
					this.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
					this.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
				});
			},
			// im发送自定义消息
			sendSay(payload) {
				var that = this;
				// 发送文本消息，Web 端与小程序端相同
				// 1. 创建消息实例，接口返回的实例可以上屏
				let message = this.$tim.createCustomMessage({
					to: this.chartId,
					conversationType: TIM.TYPES.CONV_C2C,
					payload,
				});
				// 2. 发送消息
				let promise = this.$tim.sendMessage(message);
				promise.then((imResponse) => {
						// 将某会话下所有未读消息已读上报
						let promiseOne = this.$tim.setMessageRead({
							conversationID: `C2C${this.chartId}`,
						});
						promiseOne.then(function(imResponse) {
								// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
							}).catch(function(imError) {
								// 已读上报失败
								console.warn("setMessageRead error:", imError);
							});
						// console.log(imResponse);
						let obj;
						let payload = imResponse.data.message.payload;
						let message = imResponse.data.message;
						if (payload.data == "text") {
							obj = {
								mineMsg: true,
								contactText: payload.description,
								read: false,
								time: message.clientTime,
								id: message.ID,
								avatar: message.avatar,
							};
						} else if (payload.data == "cv") {
							obj = {
								cv: true,
								contactText: payload.description,
								time: message.clientTime,
								id: message.ID,
								avatar: message.avatar,
							};
						} else if (payload.data == "connect") {
							obj = {
								connect: true,
								contactText: payload.description,
								time: message.clientTime,
								phoneShow: false,
								id: message.ID,
								avatar: message.avatar,
							};
							// hyz add：保存交换电话记录
							let user_id = this.extension.user_id;
							if(user_id){
								api.exchangePhone({'receiver': user_id, 'is_hr':1, 'im_id':message.ID, 'create_time':message.clientTime}).then((res) => {
									console.log('===exchangePhone===',res)
									if(res.code==200) that.$util.msg(res.data);
								})
							}else{
								console.log('===',user_id)
								that.$util.msg('user_id 为空', "error");
							}
						} else if (payload.data == "inte") {
							obj = {
								mineMsg: true,
								read: false,
								contactText: "面试邀请已发送，等待对方接受",
								time: message.clientTime,
								id: message.ID,
								avatar: message.avatar,
							};
						}
						this.wordList.push(obj);
						this.scrollEvent();
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},
			// 点击叉叉按钮
			differenceClick() {
				this.focusShow = true;
				this.nameInput = "";
			},
			// 搜索框获取焦点
			focusClick() {
				this.focusShow = false;
			},
			headTabClick(index) {
				this.heardTabIndex = index;
				this.checkUnread = false
				if (index == 0) {
					this.readyList();
				} else {
					let userIdList = this.chartListOne.map((item) => {
						return item.userProfile.userID;
					});
					this.userCustomize(userIdList, index);
				}
				// console.log(this.checkUnread)
			},
			// 获取用户自定义资料
			userCustomize(userIdList, index) {
				let promise = this.$tim.getUserProfile({
					userIDList: userIdList, // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
				});
				promise
					.then((imResponse) => {
						// 存储用户资料的数组 - [Profile]
						let chartList = this.chartListOne;
						let data = imResponse.data;
						let arr;
						if (index == 1) {
							arr = data.map((item) => {
								if (item.profileCustomField.length > 0 && item.profileCustomField[0].value ==
									1) {
									return item.userID;
								}
							});
						} else {
							arr = data.map((item) => {
								if (item.profileCustomField.length > 0 && item.profileCustomField[0].value ==
									2) {
									return item.userID;
								}
							});
						}
						// console.log(arr);
						let arr1 = arr.filter((item) => {
							return item;
						});
						let reason = chartList.filter((item) => {
							return arr1.includes(item.userProfile.userID);
						});
						this.chartList = reason;

					})
					.catch(function(imError) {
						console.warn("getUserProfile error:", imError); // 获取其他用户资料失败的相关信息
					});
			},
			checkUnreadClick() {
				this.checkUnread = !this.checkUnread;
				// console.log(this.checkUnread);
				if (this.checkUnread) {
					let chartList = this.chartListOne;
					this.chartList = chartList.filter((item) => {
						return item.unreadCount > 0;
					});
					if (this.chartList.length == 0) {
						this.focusShow = true;
					}
					// console.log(this.focusShow);
				} else {
					this.readyList();
				}
			},
			// 鼠标移入
			mouseenterClick(i) {
				this.pointerIndex = i;
			},
			// 获取用户简历信息
			getResume(user_id) {
				api.getResume({
					user_id
				}).then((res) => {
					if (res.code == 200) {
						if (res.data.work.length > 0) {
							res.data.work.forEach((item) => {
								if (item.to_hide == 1) {
									item.to_hide = true;
								} else {
									item.to_hide = false;
								}
								if (item.work_content) {
									item.splitAdd = item.work_content.split("\n");
								}
							});
						}
						if (res.data.school.length > 0) {
							if (res.data.school[0].experience) {
								this.experience_one = res.data.school[0].experience.split("\n");
							}
						}
						this.resumeObj = res.data;
						console.log(res.data)
					}
				});
			},

			// 获取用户的附件简历
			cvClick(id) {
				toolApi.getAnnexResume({
					user_id: id
				}).then((res) => {
					if (res.code == 200) {
						// console.log(res);
						if (res.data.annex_status == 2) {
							this.annexObjShow = true;
						}
					}
				});
			},
			// 点击联系人进行聊天
			userClick(id, extension) {
				if (extension) {
					let extensionOne = JSON.parse(extension);
					// console.log(extensionOne);
					this.extension = extensionOne;
					this.getResume(extensionOne.user_id);
					this.interObj.user_id = extensionOne.user_id;
					this.setLinkStatue(extensionOne.user_id, extensionOne.jopId);
				}
				this.getLinkStatus(id);
				this.cvClick(id);
				this.chartId = id;
				this.scrollEvent();
				this.concatShow = true;
				this.getSayList({
					conversationID: `C2C${id}`,
					count: 15
				});
				// 将某会话下所有未读消息已读上报
				let promise = this.$tim.setMessageRead({
					conversationID: `C2C${id}`
				});
				promise.then(function(imResponse) {
					// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
				}).catch(function(imError) {
					// 已读上报失败
					console.warn("setMessageRead error:", imError);
				});
			},
			// 右侧聊天，在线简历点击事件
			rightTabClick(i) {
				if (i == 1 && this.resumeObj.privates.resume_status == 3) {
					this.$util.msg("人才已设置简历隐藏", "warning");
					return;
				}
				this.rightTabIndex = i;
			},
			// 输入框绑定enter键
			handleSearchClick() {
				this.sendClick();
			},
			sendPhone(id) {
				let phone;
				if (this.userDetail.phone)  phone = this.userDetail.phone;
				else return this.$util.msg("你还未绑定手机号码，请前往绑定", "warning");
				if(this.exchangeList){
					for (let i = 0; i < this.exchangeList.length; i++) {
						let item = this.exchangeList[i];
						if(item.im_id == id){
							if(item.status != 0) {
								this.$util.msg("联系方式只能操作一次", "warning");
							}else{
								// 更新交换电话表
								this.upExchangePhone(id, 1);
								// 聊天框同意
								this.sendSay({
									data: "text",
									description: phone,
									extension: JSON.stringify(this.extension),
								});
								this.scrollEvent();
							}
							break;
						}
					}
				}else{
					console.log('exchangeList 为空')
				}
			},
			sendPhoneOne(id) {
				if(this.exchangeList){
					for (let i = 0; i < this.exchangeList.length; i++) {
						let item = this.exchangeList[i];
						if(item.im_id == id){
							if(item.status != 0) {
								this.$util.msg("联系方式只能操作一次", "warning");
							}else{
								// 更新交换电话表
								this.upExchangePhone(id, 2);
								// 聊天框拒绝
								this.sendSay({
									data: "text",
									description: "已拒绝交换联系方式",
									extension: JSON.stringify(this.extension),
								});
								this.scrollEvent();
							}
							break;
						}
					}
				}else{
					console.log('exchangeList 为空')
				}
			},
			// 发送聊天信息
			sendClick() {
				if (this.textarea.trim() == "") {
					this.$util.msg("请输入内容", "warning");
					this.textarea = "";
					return;
				}
				toolApi.checkWords({
					words: this.textarea
				}).then((res) => {
					if (res.data.words.length > 0) {
						this.$util.msg(`包含违禁词${res.data.words[0]}，请重新输入`, "error");
						return;
					} else {
						this.sendSay({
							data: "text",
							description: this.textarea,
							extension: JSON.stringify(this.extension),
						});
						this.scrollEvent();
						this.textarea = "";
					}
				});
			},
			// 控制滚动条滚动到底部
			scrollEvent() {
				// 自动滚动到底部
				this.$nextTick(() => {
					let msg = document.getElementsByClassName("chart-rightr-item")[0]; // 获取对象
					msg.scrollTop = msg.scrollHeight; // 滚动高度
				});
			},
			// 点击简历上的回复
			replyClick() {
				this.rightTabIndex = 0;
			},
			// 获取打招呼语
			getSay(type) {
				recruiterApi.getSay({
					type,
					pageSize: 100
				}).then((res) => {
					if (res.code == 200) {
						if (type == 2) {
							this.customizeList = res.data.data;
							this.commonlyShow = !this.commonlyShow;
						} else {
							this.inappList = res.data.data;
							this.inappropriateShow = !this.inappropriateShow;
						}
					}
				});
			},
			// 点击常用语图标
			commonlyClick() {
				this.getSay(2);
			},
			// 常用语鼠标移入
			mouseenterCommonly(index) {
				this.commlyIndex = index;
			},
			mouseleaveCommonly() {
				this.commlyIndex = "";
			},
			// 发送常用语
			commonlySend(content) {
				this.sendSay({
					data: "text",
					description: content,
					extension: JSON.stringify(this.extension),
				});
				this.scrollEvent();
				this.commonlyShow = false;
			},
			hideOffen(){
				this.commonlyShow = false;
			},
			// 设置常用语
			setCommonly() {
				this.$refs.setMask.show();
				this.commonlyShow = false;
			},
			// 弹出简历弹窗
			askClick() {
				if (!this.annexObjShow) {
					this.$util.msg("人才未上传附件简历", "warning");
					return;
				}
				this.askShow = !this.areaShow;
			},
			// 请求简历
			askSend() {
				this.sendSay({
					data: "cv",
					description: "简历请求已发出",
					extension: JSON.stringify(this.extension),
				});
				this.scrollEvent();
				this.askShow = false;
			},
			// 弹出交换电话弹窗
			phoneClick() {
				if (this.resumeObj.privates.protect_phone == 1) {
					this.$util.msg("对方已开启隐私保护", "warning");
					return;
				}
				this.phoneShow = !this.phoneShow;
			},
			// 请求交换电话
			phoneSend() {
				this.sendSay({
					data: "connect",
					description: "请求人才交换联系方式已发出",
					extension: JSON.stringify(this.extension),
				});
				this.scrollEvent();
				this.phoneShow = false;
			},
			// 约面试弹窗
			interviewClick() {
				this.$refs.maskModel.show();
			},
			yzgz() {
				if (this.interviewObj.position == "") {
					this.$util.msg("请选择面试职位", "warning");
					return false;
				} else if (this.timeValue == "") {
					this.$util.msg("请选择面试日期", "warning");
					return false;
				} else if (this.timeValueOne == "") {
					this.$util.msg("请选择面试时间", "warning");
					return false;
				} else if (this.interviewObj.name == "") {
					this.$util.msg("请填写联系人", "warning");
					return false;
				} else if (this.interviewObj.phone == "") {
					this.$util.msg("请填写联系方式", "warning");
					return false;
				} else if (this.interviewObj.address == "") {
					this.$util.msg("请填写面试地址", "error");
					return false;
				} else {
					return true;
				}
			},
			// 发送约面试
			interviewSendClick() {
				this.interviewObj.time = this.timeValue + " " + this.timeValueOne;
				let now = parseInt((new Date().getTime()) / 1000);
				let stm = parseInt((new Date(this.interviewObj.time).getTime()) / 1000);
				if (now > stm) return this.$util.msg("面试时间已过期", "error");

				let interviewObj = JSON.stringify(this.interviewObj);
				if (this.yzgz()) {
					this.sendSay({
						data: "inte",
						description: interviewObj,
						extension: JSON.stringify(this.extension),
					});
					this.scrollEvent();
					this.$refs.maskModel.hide();
					this.interObj.address = this.interviewObj.address;
					this.interObj.interview_time = this.interviewObj.time;
					this.interObj.hr_name = this.interviewObj.name;
					this.interObj.hr_phone = this.interviewObj.phone;
					this.interObj.remark = this.interviewObj.remark;
					api.sendInterView(this.interObj).then((res) => {
						this.$util.objClear(this.interviewObj);
						this.timeValue = "";
						this.timeValueOne = "";
					});
				}
			},
			// 不合适弹窗
			inappropriateClick() {
				if (this.link_status != 2) {
					this.getSay(3);
				} else {
					entApi
						.setLineStatue({
							link_status: 1,
							user_id: this.extension.user_id,
							position_id: this.extension.jopId,
						})
						.then((res) => {
							if (res.code == 200) {
								this.$util.msg(res.msg)
								this.getLinkStatus(this.extension.user_id);
							}
						});
				}
			},
			// 不合适发送消息
			inappropriateSendClick() {
				if (this.inappList.length == 0) {
					this.$util.msg("请先添加快捷语", "warning");
					return;
				}
				let extension = this.extension;
				extension.customStatus = 2;
				this.sendSay({
					data: "text",
					description: this.inappList[0].content,
					extension: JSON.stringify(extension),
				});
				this.scrollEvent();
				this.inappropriateShow = false;
				// 将该记录发送给后端

				entApi
					.setLineStatue({
						link_status: 2,
						user_id: this.extension.user_id,
						position_id: this.extension.jopId,
					})
					.then((res) => {
						if (res.code == 200) {
							this.$util.msg(res.msg)
							this.getLinkStatus(this.extension.user_id);
						}
					});
			},
			// 设置快捷语
			updateInapp() {
				if (this.inappList.length > 0) {
					this.inappTitle = "修改快捷语";
					this.inappQuery.id = this.inappList[0].id;
					this.inappQuery.content = this.inappList[0].content;
				} else {
					this.inappTitle = "添加快捷语";
				}
				this.$refs.inappropriateModel.show();
				this.inappropriateShow = false;
			},
			inappSubmitClick() {
				if (this.inappQuery.content.trim() == "") {
					this.$util.msg("请输入快捷语", "warning");
					return;
				}
				recruiterApi.setSay(this.inappQuery).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg);
						this.$refs.inappropriateModel.hide();
						this.getSay(3);
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.el-interactive {
		width: 100%;
		padding: 20px 147px 10px 147px;

		.interactive-select {
			width: 130px;
			height: 33px;
			margin-bottom: 16px;

			/deep/.el-input__inner {
				height: 33px;
				line-height: 33px;
			}

			/deep/.el-input__icon {
				line-height: 33px;
			}
		}

		.interactive-heard-tab {
			width: 100%;
			height: 39px;
			background: #ffffff;
			margin-bottom: 10px;
			padding-left: 20px;
			font-size: 15px;

			div {
				margin-right: 59px;
				height: 100%;
				line-height: 39px;
				position: relative;

				&:after {
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					border-bottom: 4px solid #126bf9;
					left: 0;
					bottom: 0;
					transition: 0.3s;
				}
			}

			.heardTabClass {
				color: #126bf9;

				&:after {
					width: 44px;
				}
			}

			.heardCurrent {
				&:after {
					width: 30px;
				}
			}
		}

		.el-chat-interactive {
			width: 100%;
			height: 790px;
			background: #ffffff;
			box-shadow: 1px 0px 20px 1px #f6f6f8;

			.el-chat-left {
				width: 373px;
				height: 100%;
				border-right: 1px solid #ededf1;

				.chat-left-heard {
					width: 100%;
					height: 45px;
					background: #f9fafb;
					padding: 0 20px 0 30px;

					.chat-left-heard-none-text {
						line-height: 45px;
						color: #9fa3b0;
					}

					.chat-left-heard-center {
						width: 100%;
						height: 100%;
					}

					.left-input {
						width: 70%;
						height: 100%;

						img {
							width: 16px;
							height: 14px;
							margin-right: 10px;
						}

						input {
							height: 100%;
							flex: 1;
							width: 100%;
						}
					}

					.right-box {
						color: #9fa3b0;

						div:first-child {
							width: 15px;
							height: 15px;
							background: #ffffff;
							border: 1px solid #e3e7ed;
							border-radius: 1px;
							margin-right: 7px;
						}

						img {
							width: 14px;
							height: 14px;
							margin-right: 7px;
						}
					}

					.cha-img {
						width: 16px;
						height: 16px;
					}
				}

				.chart-list {
					width: 100%;
					height: 100%;
					margin-top: 1;
					overflow-y: auto;

					.chart-list-none {
						width: 100%;
						height: 100%;
					}

					.list-item {
						width: 100%;
						height: 96px;
						padding: 0 20px 0 25px;
						margin-bottom: 10px;

						img {
							width: 56px;
							height: 56px;
							border-radius: 50%;
							margin-right: 10px;
						}

						.item-right {
							div:first-child {
								width: 100%;

								span:last-child {
									color: #9fa3b0;
									font-size: 12px;
								}
							}

							div:last-child {
								margin-top: 9px;
								width: 100%;
								color: #9fa3b0;
								font-size: 13px;
								overflow: hidden;
								text-overflow: ellipsis;
								-webkit-line-clamp: 1;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								white-space: normal;
							}
						}
					}

					.pointerClass {
						background: #f2f5fa;
					}
				}

				.chart-list::-webkit-scrollbar {
					display: none;
				}
			}

			.el-chat-right {
				width: 100%;
				height: 100%;

				.el-chart-right-heard {
					width: 100%;
					height: 46px;
					background: #f8f9fa;

					.el-chart-right-child {
						width: 100%;
						height: 100%;
					}

					.el-img {
						width: 100%;
						height: 100%;
					}

					.el-right-button-tab {
						left: 0;
						width: 100%;
						padding-left: 66px;
						height: 100%;
						line-height: 54px;

						div:first-child {
							margin-right: 30px;
							width: 56px;
						}

						.active-tab {
							opacity: 0.8;
						}
					}
				}

				.chart-right-center {
					width: 100%;
					height: 100%;

					padding-top: 72px;

					.center-user-detail {
						width: 100%;
						height: 72px;
						background: #ffffff;
						box-shadow: 0px 3px 3px 0px rgba(163, 162, 163, 0.1);
						padding: 10px 30px;
						color: #61687c;

						.user-detail-left {
							height: 100%;

							.user-name {
								margin-right: 13px;
							}

							.user-job-detail {
								div {
									margin: 0 6px;
								}
							}

							.user-job-one {
								margin: 0 19px 0 12px;
							}

							.user-job {
								margin-right: 12px;
							}
						}
					}

					.chart-rightr-item {
						width: 100%;
						height: 470px;
						overflow-y: auto;
						padding: 30px;

						.word {
							margin-bottom: 29px;

							img {
								width: 37px;
								height: 37px;
								border-radius: 50%;
								margin-right: 10px;
							}

							div {
								max-width: 300px;
								padding: 10px 15px;
								background: #f2f2f2;
								border-radius: 4px;
								color: #333;
								flex-wrap: wrap;
								justify-content: flex-start;
							}
						}

						.el-word-phone {
							margin-bottom: 29px;
							align-items: flex-end;

							img {
								width: 37px;
								height: 37px;
								border-radius: 50%;
								margin-right: 10px;
							}

							.word-phone-div {
								width: 240px;
								border-radius: 5px;
								background: #ffffff;
								box-shadow: 1px 1px 4px #888888;
								height: 80px;
								display: flex;
								flex-direction: column;

								>div:first-child {
									text-align: center;
									padding: 10px;
									border-bottom: 1px solid #eee;
									-webkit-line-clamp: 1;
								}

								.word-phone-button {
									flex: 1;
									height: 100%;
									align-items: center;

									div {
										width: 50%;
										height: 50%;
										display: flex;
										align-items: center;
										justify-content: center;
									}

									div:first-child {
										border-right: 1px solid #eee;
										color: #999;
									}

									.checkCv-class {
										width: 100%;
										border: none;
										color: #126bf9 !important;
									}
								}
							}
						}

						.word-time {
							margin-bottom: 25px;
							color: #9fa3b0;
						}

						.exchange {
							margin: 30px auto;
							width: 200px;
							height: 40px;
							border-radius: 20px;
							background: #f2f5f9;
						}

						.word-my {
							justify-content: flex-end;
							align-items: flex-start;
							margin-bottom: 29px;

							.el-have-read {
								// width: 30px;
								padding: 5px 8px;
								height: 16px;
								background: #75d37d;
								border-radius: 5px;
								font-size: 12px;
							}

							.read-false {
								background: #a3d3fc;
							}

							.word-my-center {
								margin-left: 10px;

								div {
									max-width: 300px;
									padding: 10px 15px;
									border-radius: 4px;
									flex-wrap: wrap;
									justify-content: flex-start;
								}

								img {
									width: 37px;
									height: 37px;
									border-radius: 50%;
									margin-left: 10px;
								}
							}
						}
					}
				}

				.chart-rightr-item::-webkit-scrollbar {
					display: none;
				}

				.commonly-userd {
					.el-commonly {
						top: -338px;
						left: -5px;

						.el-img {
							width: 584px;
							height: 349px;
						}

						.commonly-center {
							width: 100%;
							height: 100%;
							// background: red;
							padding: 16px 13px 14px 12px;

							.commonly-item {
								width: 100%;
								height: 100%;
								overflow-y: auto;

								.item {
									width: 100%;
									height: 52px;
									border-bottom: 1px solid #f3f5fa;
									padding: 0 26px 0 21px;
									cursor: pointer;

									.item-heard {
										color: #9fa3b0;
									}
								}

								.not-commonly {
									width: 100%;
									height: 200px;
									display: flex;
									align-items: center;
									justify-content: center;
									color: #9fa3b0;
									font-size: 20px;
								}

								.item:last-child {
									border-bottom: none;
								}

								.item-index {
									background: #f2f4f9;
								}
							}

							.commonly-item::-webkit-scrollbar {
								display: none;
							}
						}
					}

					.ask-for {
						width: 336px;
						height: 187px;
						top: -170px;
						left: -70px;

						img {
							width: 100%;
							height: 100%;
						}

						.ask-center {
							width: 100%;
							height: 100%;
							justify-content: space-evenly;

							>div:first-child {
								color: #61687c;
								font-size: 17px;
							}

							>div:last-child {
								div {
									width: 100px;
									height: 40px;
									border-radius: 2px;
								}

								.ask-botton {
									background: #ffffff;
									border: 1px solid #f6f6f8;
									margin-right: 26px;
								}
							}
						}
					}

					.phone-for {
						left: -5px;
					}

					.inappropriate {
						width: 365px;
						height: 143px;
						top: -120px;
						right: -30px;

						img {
							width: 100%;
							height: 100%;
						}

						.inappropriate-center {
							width: 100%;
							height: 100%;
							padding: 16px;
							padding-bottom: 0;
							justify-content: center;

							div {
								width: 100%;
								height: 56px;
								border-bottom: 1px solid #f3f5fa;
							}

							div:first-child {
								padding: 0 22px 0 19px;

								span:first-child {
									color: #9fa3b0;
								}
							}

							div:last-child {
								border-bottom: none;
								height: 66px;
								-webkit-line-clamp: 1;
								text-align: center;
								line-height: 56px;
							}
						}
					}
				}

				.chart-right-bottom {
					width: 100%;
					height: 202px;
					border-top: 2px solid #ededf1;
					padding: 15px 28px 14px 30px;

					.right-bottom-img {
						color: #61687c;

						.right-bottom-img-left {
							img {
								width: 22px;
								height: 22px;
							}

							.div-one-img {
								margin-left: 20px;

								img {
									width: 18px;
									margin-right: 5px;
								}

								.resumeObj {
									color: #999;
								}
							}

							.div-two-img {
								margin-left: 20px;

								img {
									width: 19px;
									height: 19px;
									margin-right: 4px;
								}

								.div-two-text {
									color: #e2e2e2;
								}
							}

							.div-three-img {
								img {
									width: 21px;
									height: 16px;
									margin-right: 3px;
								}
							}

							.div-four-img {
								margin-left: 20px;

								img {
									width: 15px;
									height: 15px;
									margin-right: 5px;
								}
							}
						}
					}

					.chart-input {
						width: 100%;
						height: 100%;

						/deep/.el-textarea {
							width: 100%;
							height: 100%;
						}

						/deep/.el-textarea__inner {
							width: 100%;
							height: 100%;
							border: none;
							resize: none;
						}
					}

					.send-button {
						width: 100%;
						height: 28px;
						font-weight: 300;
						color: #d1d4db;
						align-items: center;

						div:last-child {
							width: 62px;
							height: 28px;
							border: 1px solid #dcdee1;
							border-radius: 14px;
							margin-left: 24px;
						}

						.area-active {
							border: 1px solid #126bf9 !important;
							color: #126bf9 !important;
						}
					}
				}

				.right-conent-cv {
					width: 100%;
					height: 100%;

					.cv-detail {
						width: 100%;
						height: 100%;
						overflow-y: auto;
						padding: 40px 39px 0 42px;

						img {
							width: 61px;
							height: 61px;
							border-radius: 50%;
							margin-right: 23px;
						}

						.el-detail-text {
							width: 100%;

							.el-detail-text-one {
								width: 100%;
								height: 61px;
								align-items: flex-start;
								margin-bottom: 39px;

								.detail-text-one-left {
									height: 100%;
									font-size: 21px;

									div:last-child {
										color: #61687c;
									}
								}

								.detail-text-one-right {
									div {
										margin: 0 6px;
									}
								}
							}

							.el-detail-text-two {
								margin-bottom: 39px;

								div:first-child {
									margin-right: 10px;
								}
							}

							.el-detail-text-three {
								margin-bottom: 28px;

								.text-three-left {
									div:last-child {
										margin-left: 6px;
									}
								}
							}

							.el-detail-text-four {
								margin-bottom: 40px;

								.el-detail-text-four-item {
									margin-bottom: 14px;
								}

								.el-detail-text-four-item-span {
									margin-left: 10px;
								}
							}

							.el-detail-text-five {
								margin-bottom: 30px;

								.el-detail-text-five-text {
									div {
										margin: 0 6px;
									}
								}
							}
						}
					}

					.cv-detail-button {
						width: 100%;
						height: 71px;
						border-top: 1px solid #ededf1;
						padding-right: 40px;
						font-weight: 300;
						color: #61687c;
						align-items: center;

						.el-button-hf {
							width: 55px;
							height: 24px;
							border: 1px solid #b5b5c1;
							border-radius: 4px;
							margin-left: 19px;
						}

						img {
							width: 15px;
							height: 15px;
							margin-right: 5px;
						}
					}
				}

				.chart-id-class {
					width: 100%;
					height: 100%;
					justify-content: center;
					align-items: center;

					img {
						width: 116px;
						height: 113px;
						margin-bottom: 98px;
					}

					color: #414a60;
					font-size: 14px;

					span {
						color: #9fa3b0;
					}
				}
			}
		}

		.update-eidt {
			width: 100%;
			margin: 30px 0;
			padding-right: 30px;

			.eift-item {
				margin-bottom: 21px;

				.div-select {
					width: 290px;
					margin-left: 20px;

					/deep/.el-input--suffix {
						width: 290px;
					}

					/deep/.el-textarea__inner {
						height: 90px;
					}
				}

				.div-select-one {
					margin-left: 6px;
				}
			}

			.eift-item-one {
				margin-bottom: 21px;

				.select-item {
					margin-left: 20px;

					.select-item-div {
						width: 140px;

						/deep/.el-input {
							width: 140px;
						}
					}

					.select-item-div-one {
						margin-right: 10px;
					}
				}
			}
		}

		.inappropriate-model {
			margin-top: 41px;
			margin-bottom: 30px;
			width: 100%;
			height: 90px;

			/deep/.el-textarea {
				width: 100%;
				height: 100%;
			}

			/deep/.el-textarea__inner {
				height: 100%;
			}
		}
	}
</style>
